@font-face {
  font-family: "killerfont"; /* Project id 3916466 */
  src: url('killerfont.woff2?t=1677296052385') format('woff2'),
       url('killerfont.woff?t=1677296052385') format('woff'),
       url('killerfont.ttf?t=1677296052385') format('truetype');
}

.killerfont {
  font-family: "killerfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-redeem:before {
  content: "\e602";
}

.icon-governance:before {
  content: "\e603";
}

.icon-bond:before {
  content: "\e604";
}

.icon-docs:before {
  content: "\e605";
}

.icon-calculator:before {
  content: "\e606";
}

.icon-stake:before {
  content: "\e607";
}

.icon-ido:before {
  content: "\e608";
}

.icon-dashboard:before {
  content: "\e609";
}

